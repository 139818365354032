module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"678997a3bb084ad0fe352d3af59fd83f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/vinhnguyen/Documents-Nux/Koudepouce/sites/www.spring-medicare.fr/www.spring-medicare.fr/gatsby","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-flexsearch/gatsby-browser.js'),
      options: {"plugins":[],"languages":["fr"],"type":"MarkdownRemark","fields":[{"name":"praticien_cabinet","indexed":true,"resolver":"frontmatter.praticien_cabinet","attributes":{"encode":"balance","tokenize":"full","threshold":6,"depth":3},"store":true},{"name":"titre","indexed":true,"resolver":"frontmatter.praticien_titre","attributes":{"encode":"balance","tokenize":"full","threshold":6,"depth":3},"store":true},{"name":"description","indexed":true,"resolver":"frontmatter.description","attributes":{"encode":"balance","tokenize":"full","threshold":6,"depth":3},"store":true},{"name":"praticien_nom","indexed":true,"resolver":"frontmatter.praticien_nom","attributes":{"encode":"balance","tokenize":"full","threshold":6,"depth":3},"store":true},{"name":"praticien_prenom","indexed":true,"resolver":"frontmatter.praticien_prenom","attributes":{"encode":"balance","tokenize":"full","threshold":6,"depth":3},"store":true},{"name":"praticien_localite","indexed":true,"resolver":"frontmatter.praticien_localite","attributes":{"encode":"balance","tokenize":"full","threshold":6,"depth":3},"store":false},{"name":"url","indexed":false,"resolver":"frontmatter.slug","store":true},{"name":"url_photo","indexed":false,"resolver":"frontmatter.embeddedImagesLocal","store":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
